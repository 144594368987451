import * as api from '../api';

export const newGame = () => async(dispatch) => {
    try {
        const { data } = await api.newGame();
        dispatch({type:'FETCH_ALL', payload: data});
    } catch (error) {  
        console.log(error.message)
    }
}
