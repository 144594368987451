import React from 'react';
import { useEffect, useState } from 'react';
import { Game } from './components/Game';
import { useDispatch } from 'react-redux';
import { newGame } from './actions/game.js';
import './App.css';
// test
const App = () => {
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(newGame())
            .then(() => setLoading(false))
    }, [dispatch])

    return(
    <div className="App">
        <Game loading = {loading} setLoading = {setLoading} />
    </div>
    )
}

export default App;

