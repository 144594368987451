import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux'
import Grid from "@material-ui/core/Grid";
import Cell from './Cell';
import Category from './Category';
import {MdSkipNext} from 'react-icons/md';
import {FaArrowAltCircleRight, FaQuestionCircle} from 'react-icons/fa'
import {MdArrowBack} from 'react-icons/md'


export const Game = (props) => {

    const [finalJeopardy, setFinalJeopardy] = useState({})
    const [jQuestions, setjQuestions] = useState({})
    const [djQuestions, setdjQuestions] = useState({})
    const [guess, setGuess] = useState('')
    const [board, setBoard] = useState(true)
    const [buzzIn, setBuzzIn] = useState(false)
    const [currQuestion, setCurrQuestion] = useState('')
    const [currCategory, setCurrCategory] = useState('')
    const [currAnswer, setCurrAnswer] = useState('')
    const [currValue, setCurrValue] = useState(0)
    const [correct, setCorrect] = useState(false)
    const [incorrect, setIncorrect] = useState(false)
    const [score, setScore] = useState(0)
    const [seen, setSeen] = useState([])
    const [dj, setDj] = useState(false)
    const [fj, setFj] = useState(false)
    const [bet, setBet] = useState(false)
    const [betValue, setBetValue] = useState(0)
    const [finalScore, setFinalScore] = useState(false)
    const [about, setAbout] = useState(false)

    const game = useSelector((state)=>state.game)

    useEffect(() => {
        try {
            var temp = game['game']['jeopardy']
            var temp1 = Object.keys(game['game']['jeopardy'])
            var temp2 = {}
            for (let index = 0; index < temp1.length; index++) {
                temp2[temp1[index]] = []
                for (let i= 0; i < temp[temp1[index]].length; i++) {
                    temp2[temp1[index]].push(game['game']['jeopardy'][temp1[index]][i])
                }
            }
            setjQuestions(temp2)
        } catch (error) {
            console.log(error.message)
        }
    }, [game])

    useEffect(() => {
        try {
            var temp = game['game']['doubleJeopardy']
            var temp1 = Object.keys(game['game']['doubleJeopardy'])
            var temp2 = {}
            for (let index = 0; index < temp1.length; index++) {
                temp2[temp1[index]] = []
                for (let i= 0; i < temp[temp1[index]].length; i++) {
                    temp2[temp1[index]].push(game['game']['doubleJeopardy'][temp1[index]][i])
                }
            }
            setdjQuestions(temp2)
        } catch (error) {
            console.log(error.message)
        }
    }, [game])

    useEffect(() => {
        try {
            var temp = game['game']['finalJeopardy']
            setFinalJeopardy(temp)
        } catch (error) {
            console.log(error.message)
        }
    }, [game])

    const handleChange = (e) => {
        setGuess(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(guess.toLowerCase().replace('the ', '').replace(/ *\([^)]*\) */g, '') === currAnswer.toLowerCase().replace('the ', '').replace(/ *\([^)]*\) */g, '')){
            setCorrect(true)
        }
        else{
            setIncorrect(true)
        }
        setGuess('')
    }

    const handleBet = (e) => {
        setBetValue(e.target.value)
    }

    const handleBetSubmit = (e) => {
        e.preventDefault();
        setBet(true)
    }

    const handleFJSubmit = (e) => {
        e.preventDefault();
        if(guess.toLowerCase().replace('the ', '').replace(/ *\([^)]*\) */g, '') === finalJeopardy['Answer'].toLowerCase().replace('the ', '').replace(/ *\([^)]*\) */g, '')){
            setCorrect(true)
            setScore(score + parseInt(betValue))
        }
        else{
            setIncorrect(true)
            setScore(score - parseInt(betValue))
        }
        setGuess('')

    }

    const skipQuestion = (e) => {
        setBoard(true)
        setBuzzIn(false)
    }

    const backToBoard = (e) => {
        if(correct){
            setScore(score + currValue)
        }
        else{
            setScore(score - currValue)
        }
        setCurrQuestion('')
        setCurrCategory('')
        setCurrAnswer('')
        setCurrValue(0)
        setBoard(true)
        setBuzzIn(false)
        setCorrect(false)
        setIncorrect(false)
        let temp = [...seen]
        temp.push(currQuestion)
        setSeen(temp)
    }

    const moveOnToDJ = (e) => {
        setDj(true)
        setGuess('')
    }

    const moveOnToFJ = (e) => {
        setFj(true)
        setDj(false)
        setGuess('')
    }
    
    const newGame = (e) => {
        window.location.reload();
        return false;   
    }
    
    const handleAbout = (e) => {
        setAbout(true)
    }

    const handleGoBack = (e) => {
        setAbout(false)
    }

    const moveOnToFinalScore = (e) => {
        setFinalScore(true)
        setAbout(false)
        setFj(false)
        setDj(false)
        setBuzzIn(false)
        setBoard(false)
        setCorrect(false)
        setIncorrect(false)
    }

    function removeTags(str) {
        if ((str===null) || (str===''))
            return false;
        else
            str = str.toString();
              
        return str.replace( /(<([^>]+)>)/ig, '');
    }


    return(
        <div>

            <div className="game">

            {Object.keys(game).length >= 1 && !about && 
            <button className = "aboutButton" onClick = {(e) => handleAbout(e)}><FaQuestionCircle size={24}/></button>
            }


            {Object.keys(game).length >= 1 && about &&
            <div className = "backButtonContainer">
            <div className='aboutContainer'>
            <div className="metal clip raise">Web Jeopardy</div>
            <div className="aboutParagraph">Play Jeopardy online! Answer thousands of different jeopardy questions from real games aired between 1984 and 2012.
            </div>
            {/* <div className="aboutParagraph">
            NOTE: Prize amounts for categories do not follow a standard schema (ex. $200, $400, $600, $800, $1000).
            </div> */}
            <div className="aboutParagraph">
            NOTE: The filter for correct answers is not perfect; answer format is specific and some responses may be incorrectly penalized.
            </div>
            <div className="aboutParagraph">
            NOTE: Some clues reference an image, video, or audio clip. Unfortunately, there is no way to access this media.
            </div>
            <div className="aboutParagraph">
            NOTE: All information was scraped from J-Archive. The data in this app is only as good as the data in J-Archive. 
            </div>
            </div>
            <div className="dedication">
                Not for profit. Dedicated to Alex Trebek.
            </div>

            <button className = "backButton"  onClick = {(e) => handleGoBack(e)}><MdArrowBack size={24}/></button>
            </div>
            
            }

            {Object.keys(game).length >= 1 && !about && 
            <div className = "score">

            {score < 0 && !finalScore &&  !about && 
            <div>
                -${score*-1}
            </div>
            }

            {score >= 0 && !finalScore &&  !about && 
            <div>
                ${score}
            </div>
            }
            
            </div>
            }

            {Object.keys(game).length < 1 && !props.loading && !about && 
            <div className = "errorMessage">There was an error creating a new game. This is likely due to server issues. <button className = "refreshButton" onClick={(e) => window.location.reload()}>Click here</button> to refresh the page. </div>
            }

            {props.loading && !correct && !incorrect &&  !about && 
                <div className = "loadingBlock">
                <div id="loading"></div>
                <div className = "loadingNewGame">Loading new game please wait...</div>
                </div>
            }

            {board && !dj && !fj && !correct && !incorrect && !about && 

            <div className = "board">
            {Object.keys(game).length >= 1 &&  !about && 
            <div>
                <div className="metal clip raise">Jeopardy</div>
            </div>}
            <Grid container>
            {Object.keys(jQuestions).map((category, index) =>
                    <Grid item>
                        <Category value = {category}/>
                    </Grid>
            )}
            </Grid>
            <Grid container>
            {Object.keys(jQuestions).map((category, index) =>
                <div>
                {Object.keys(jQuestions[category]).map((field, index) => <div>
                    <Grid item>
                        <Cell
                        seen = {seen}
                        setSeen = {setSeen}
                        currValue = {currValue}
                        setCurrValue = {setCurrValue}
                        currAnswer = {currAnswer}
                        setCurrAnswer = {setCurrAnswer}
                        currQuestion = {currQuestion}
                        setCurrQuestion = {setCurrQuestion}
                        currCategory = {currCategory}
                        setCurrCategory = {setCurrCategory}
                        board = {board}
                        setBoard = {setBoard}
                        buzzIn = {buzzIn}
                        setBuzzIn = {setBuzzIn}
                        question = {removeTags(jQuestions[category][index]['Question'])} 
                        answer = {removeTags(jQuestions[category][index]['Answer'])}
                        value = {'$' + (index+1)*200}
                        // value = {jQuestions[category][index]['Value'].replace(',','')}
                        category = {jQuestions[category][index]['Category']}
                        />
                    </Grid>
                </div>)}
                </div>
            )}
            </Grid>
            {Object.keys(game).length >= 1 &&  !about && 
            <button className = " moveOnToDJButton" onClick = {(e) => moveOnToDJ(e)}>
            Move on to Double Jeopardy!
            <FaArrowAltCircleRight className = "arrowIcon" size = {28}/>
            </button>
            }
            </div>
            }

            {board && dj && !fj && !correct && !incorrect && !props.loading && !about && 
            <div className = "board">
            <div className="metal clip raise">Double Jeopardy</div>
            <Grid container>
            {Object.keys(djQuestions).map((category, index) =>
                    <Grid item>
                        <Category value = {category}/>
                    </Grid>
            )}
            </Grid>
            <Grid container>
            {Object.keys(djQuestions).map((category, index) =>
                <div>
                {Object.keys(djQuestions[category]).map((field, index) => <div>
                    <Grid item>
                        <Cell
                        seen = {seen}
                        setSeen = {setSeen}
                        currValue = {currValue}
                        setCurrValue = {setCurrValue}
                        currAnswer = {currAnswer}
                        setCurrAnswer = {setCurrAnswer}
                        currQuestion = {currQuestion}
                        setCurrQuestion = {setCurrQuestion}
                        currCategory = {currCategory}
                        setCurrCategory = {setCurrCategory}
                        board = {board}
                        setBoard = {setBoard}
                        buzzIn = {buzzIn}
                        setBuzzIn = {setBuzzIn}
                        question = {removeTags(djQuestions[category][index]['Question'])} 
                        answer = {removeTags(djQuestions[category][index]['Answer'])}
                        value = {'$' + (index+1)*400}
                        // value = {djQuestions[category][index]['Value'].replace(',','')}
                        category = {djQuestions[category][index]['Category']}
                        />
                    </Grid>
                </div>)}
                </div>
            )}
            </Grid>
            <button className = "moveOnToFJButton"  onClick = {(e) => moveOnToFJ(e)}>
            Move on to Final Jeopardy!
            <FaArrowAltCircleRight className = "arrowIcon" size = {28}/>
            </button>
            </div>
            }

            {buzzIn && !correct && !incorrect && !dj &&  !about && 
            <div className = "currContainer">
            <div  className="metal clip raise">Jeopardy</div>
            <div className = "currQuestion">
            <div className = "currCategory">
            {currCategory}
            </div>
            <div className = "currQ">
            {currQuestion.toUpperCase()}
            </div>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
            <div className = "inputBlock">
                <input className = "answerInput" type="text" value={guess} onChange={(e) => handleChange(e)} />
                <button type="button" className = "skipButton" onClick = {(e) => skipQuestion(e)}><MdSkipNext size={36}/></button>
                </div>
            </form>
            </div>
            }

            {buzzIn && !correct && !incorrect && dj &&  !about && 
            <div className = "currContainer">
            <div className="metal clip raise">Double Jeopardy</div>
            <div className = "currQuestion">
            <div className = "currCategory">
            {currCategory}
            </div>
            <div className = "currQ">
            {currQuestion.toUpperCase()}
            </div>
            </div>
            <form onSubmit={(e) => handleSubmit(e)}>
            <div className = "inputBlock">
                <input className = "answerInput" type="text" value={guess} onChange={(e) => handleChange(e)} />
                <button type="button" className = "skipButton" onClick = {(e) => skipQuestion(e)}><MdSkipNext size={36}/></button>
                </div>
            </form>
            </div>
            }

            {fj && !dj && !correct && !incorrect && !bet && !about && 
            <div className = "currContainer">
            <div className="metal clip raise">Final Jeopardy</div>
            <div className = "currQuestion">
            <div className = "currQ">
            {finalJeopardy['Category']}
            </div>
            </div>
            <form onSubmit={(e) => handleBetSubmit(e)}>
            <span className = "wagerAmt">Please enter your wager : </span>
                <input className = "answerInput" max = {score >= 0 ? score : 0} min = {0} type="number" value= {betValue} onChange={(e) => handleBet(e)} />
            </form>
            </div>
            }

            {fj && !dj && !correct && !incorrect && bet && !about && 
            <div className = "currContainer">
            <div className="metal clip raise">Final Jeopardy</div>
            <div className = "currQuestion">
            <div className = "currCategory">
            {finalJeopardy['Category']}
            </div>
            <div className = "currQ">
            {removeTags(finalJeopardy['Question'].toUpperCase())}
            </div>
            </div>
            <form onSubmit={(e) => handleFJSubmit(e)}>
                <input className = "answerInput" type="text" value={guess} onChange={(e) => handleChange(e)} />
            </form>
            </div>
            }

            {correct && !fj && !dj &&  !about && 
            <div>
            <div className="metal clip raise">Jeopardy</div>
            <div className = "con">
            <button className = "backButton"  onClick = {(e) => backToBoard(e)}><MdArrowBack size={24}/></button>
            <div className = "correctScreen">
            <div className = "test">Correct! The answer was {currAnswer}.
            <div/>
            +${currValue}
            </div>
            </div>
            </div>
            </div>
            }

            {correct && !fj && dj &&  !about && 
            <div>
            <div className="metal clip raise">Double Jeopardy</div>
            <div className = "con">
            <button className = "backButton"  onClick = {(e) => backToBoard(e)}><MdArrowBack size={24}/></button>
            <div className = "correctScreen">
            <div className = "test">Correct! The answer was {currAnswer}.
            <div/>
            +${currValue}
            </div>
            </div>
            </div>
            </div>
            }

            {correct && fj && betValue === 0  && !finalScore && !about && 
            <div>
            <div className="metal clip raise">Final Jeopardy</div>
            <div className = "correctScreen">
            <div className = "test">Correct! The answer was {removeTags(finalJeopardy['Answer'])}.
            <div/>
            +${betValue}
            <div/>
            <button onClick = {(e) => moveOnToFinalScore(e)} className = "newGame">End Game</button>
            </div>
            </div>
            </div>
            }

            {correct && fj && betValue > 0 && !finalScore && !about && 
            <div>
            <div className="metal clip raise">Final Jeopardy</div>
            <div className = "correctScreen">
            <div className = "test">Correct! The answer was {removeTags(finalJeopardy['Answer'])}.
            <div/>
            <div/>
            <button onClick = {(e) => moveOnToFinalScore(e)} className = "newGame">End Game</button>
            </div>
            </div>
            </div>
            }

            {incorrect && !fj && !dj && !about && 
                <div>
                <div className="metal clip raise">Jeopardy</div>
                <div className = "con">
                <button className = "backButton"  onClick = {(e) => backToBoard(e)}><MdArrowBack size={24}/></button>
            <div className = "incorrectScreen">
            <div className = "test">Incorrect, the answer was {removeTags(currAnswer)}.
            <div/>
            -${currValue}
            </div>
            </div>
            </div>
            </div>
            }

            {incorrect && !fj && dj && !about &&  
                <div>
                <div className="metal clip raise">Double Jeopardy</div>
                <div className = "con">
                <button className = "backButton"  onClick = {(e) => backToBoard(e)}><MdArrowBack size={24}/></button>
            <div className = "incorrectScreen">
            <div className = "test">Incorrect, the answer was {removeTags(currAnswer)}.
            <div/>
            -${currValue}
            </div>
            </div>
            </div>
            </div>
            }

            {incorrect && fj && betValue > 0 && !finalScore && !about && 
                <div>
                <div className="metal clip raise">Final Jeopardy</div>
            <div className = "incorrectScreen">
            <div className = "test"> That'll cost you! The answer was {removeTags(finalJeopardy['Answer'])}.
            <div/>
            <div/>
            <button onClick = {(e) => moveOnToFinalScore(e)} className = "newGame">End Game</button>
            </div>
            </div>
            </div>
            }

            {incorrect && fj && betValue === 0 && !finalScore && !about && 
                <div>
                <div className="metal clip raise">Final Jeopardy</div>
            <div className = "incorrectScreen">
            <div className = "test">Incorrect, the answer was {removeTags(finalJeopardy['Answer'])}.
            <div/>
            <button onClick = {(e) => moveOnToFinalScore(e)} className = "newGame">End Game</button>
            </div>
            <div/>
            </div>
            </div>
            }

            {finalScore && !about && !fj && !dj && !buzzIn && !board && !correct && !incorrect &&
            <div className = "finalScoreScreen">
            <div className="offset">
                <div className = "currQuestion">
                <div className = "test">Congratulations, you ended the game with a score of {score}.
                <div/>
                <button onClick = {(e) => newGame(e)} className = "newGame">New Game</button>
                </div>
                </div>
            </div>
            </div>
            }
            </div>
            </div>
    )
}