import React from 'react';
import '../App.css';

const Category = (props) => {
    return(
        <button className="category">
            {props.value}
        </button>
    )
}

export default Category;