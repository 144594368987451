import React, {useState} from 'react';
import '../App.css';

const Cell = (props) => {

    const [showingQuestion, setShowingQuestion] = useState(false)

    const showQuestion = (e) => {
        setShowingQuestion(true)
        props.setCurrQuestion(props.question)
        props.setCurrCategory(props.category)
        props.setCurrAnswer(props.answer)
        props.setCurrValue(parseInt(props.value.substring(1)))
        props.setBoard(false)
        props.setBuzzIn(true)
    }

    return(
        <div>
            {props.seen.includes(props.question) && 
            <div className = "emptyCell"/>
            }
            {!showingQuestion && !props.seen.includes(props.question) &&
            <button category = {props.category} question = {props.question} value = {props.value} onClick = {(e) => showQuestion(e)} className="cell">
            <div className="valueShadow">{props.value}</div>
            </button>
            }
        </div>
    )
}

export default Cell;